// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/2
// +-------------------------------------------------------------
// |Time: 14:40
// +-------------------------------------------------------------
export default [
    {
        path: "/game/game-list",
        name: "GameList",
        meta: {
            title: "游戏列表",
            menu:true,
        },
        component: () => import("@/views/game/Game/GameList.vue")
    },
    {
        path: "/game/discount-game",
        name: "DiscountGame",
        meta: {
            title: "0.1折游戏推广页",
            menu:true,
            level:2
        },
        component: () => import ("@/views/game/DiscountGame.vue")
    },
    {
        path: "/game/discount-game-set",
        name: "DiscountGameSet",
        meta: {
            title: "0.1折自助设置",
            menu:true,
            level:2
        },
        component: () => import ("@/views/game/DiscountGameSet.vue")
    },
    {
        path: "/game/third-game",
        name: "thirdGameList",
        meta: {
            title: "代充游戏",
            menu:true,
        },
        component: () => import("@/views/game/thirdGame/List.vue")
    },
    {
        path: "/game/child-apply-game",
        name: "childApplyGame",
        meta: {
            title: "子渠道游戏",
            menu:true,
        },
        component: () => import("@/views/game/childApply/List.vue")
    }
]
